<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ metadata && metadata.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ metadata && metadata.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证号</span>
                      <span class="descriptions-item-content">
                        {{ metadata && metadata.idNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">发起合同</div>
            </div>
            <div class="descriptions-view">
              <div>
                <el-form :model="pageForm"
                         :rules="pageFormRules"
                         ref="formRef"
                         label-position="left"
                         label-width="120px">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="签订方式" prop="签订方式">
                        <el-radio v-model="signingMode" label="ONLINE">线上签约</el-radio>
                        <el-radio v-model="signingMode" label="OFFLINE">线下签约</el-radio>
                      </el-form-item>
                    </el-col>
                    <el-col :offset="2" :span="8"></el-col>
                  </el-row>
                  <el-row v-if="signingMode === 'ONLINE'">
                    <el-col :span="8">
                      <el-form-item label="合同类型" prop="consumeType" required
                                    :rules="{ required: this.signingMode === 'ONLINE', message: '请选择合同类型' }">
                        <el-select v-model="pageForm.consumeType" clearable>
                          <el-option v-for="iter in CONTRACT_TYPE"
                                     :key="iter.label"
                                     :label="iter.label"
                                     :value="iter.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :offset="2" :span="8">
                      <el-form-item label="合同模板" prop="templateId" required
                                    :rules="{ required: this.signingMode === 'ONLINE', message: '请选择合同模板' }">
                        <el-select v-model="pageForm.templateId" clearable @change="templateChange">
                          <el-option v-for="iter in contractTemplateRegion"
                                     :key="iter.templateId"
                                     :label="iter.templateName"
                                     :value="iter.templateId"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 合同字段 -->
                  <div v-if="currContractTemplate.components && signingMode === 'ONLINE'">
                    <el-form :model="contractField"
                             :rules="contractFieldRules"
                             ref="contractFieldRef">
                      <el-row v-for="iter in currContractTemplate.components"
                              :key="iter.componentName">
                        <el-col :span="8">
                          <el-form-item :label="iter.componentDescription"
                                        :prop="iter.componentName"
                                        :required="iter.componentRequired"
                                        :rules="{
                                          required: iter.componentRequired,
                                          message: iter.componentType === 'TEXT' ? `请填写` : '请选择'
                                        }">
                            <!-- 输入字段 -->
                            <el-input v-if="iter.componentType === 'TEXT'"
                                      v-model="contractField[iter.componentName]"
                                      style="width: 100%;"
                                      :disabled="iter.isDisable"></el-input>
                            <!-- 选择字段 -->
                            <el-radio-group v-if="iter.componentType === 'CHECK_BOX'"
                                            v-model="contractField[iter.componentName]">
                              <el-radio v-for="item in iter.options"
                                        :key="item.name"
                                        :label="item.value">{{ item.name }}</el-radio>
                            </el-radio-group>
                          </el-form-item>
                        </el-col>
                        <el-col :offset="2" :span="8"></el-col>
                      </el-row>
                    </el-form>
                  </div>

                  <el-row v-if="signingMode === 'OFFLINE'">
                    <el-col :span="12">
                      <el-form-item prop="files" required label="合同图片"
                                    :rules=" { required: this.signingMode === 'OFFLINE', message: '请上传合同照片' }">
                        <el-upload accept="image/*"
                                   class="upload-demo"
                                   action=""
                                   ref="filesImgUpload"
                                   list-type="picture-card"
                                   :file-list="imageList"
                                   :on-remove="(f, fl) => this.imageList = fl"
                                   :http-request="handlerUploadImage">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>

          <div
              class="descriptions"
              style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">操作</div>
            </div>
            <div class="descriptions-view">
              <el-button @click="closePage">取消</el-button>
              <el-button type="primary" @click="submit">提交</el-button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <el-dialog title="微信扫码签署合作协议"
               destroy-on-close
               :visible.sync="signUrlShow"
               width="30%">
      <div style="padding: 10px;display: flex;justify-content: center;">
        <el-image style="width: 300px;height: 300px;"
                  :src="signUrl"></el-image>
      </div>
      <div style="padding-top: 20px;text-align:center;">
        <div>企业已经静默完成签署</div>
        <div>请用户扫描二维码签约</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {fileUpload, getProjectUserById, projectOffline, saveEsign, templateAll} from "@/api/apis";
import {exportPicture, viewImages} from "@/utils/common";
import helper, {gridConnectionStatusMapper, statusMapper} from "@/utils/helper";
import {CONTRACT_TYPE, INTENTION_CONSUMPTION_STATE, PIC_URL} from "@/utils/config";
export default {
  name: "Info",
  components: {PageHeader},
  data(){
    return {
      getData: getProjectUserById,
      id: '',
      metadata: {},

      farmer: {},
      workOrder: null,
      roofTypeModel: null,
      user: null,
      designData: {},
      INTENTION_CONSUMPTION_STATE,
      CONTRACT_TYPE,
      designDataFiles:[],

      // 线下签约
      pageForm: {
        // 线下签约 合同照片
        files: [],
        // 线上签约
        // 合同类型
        consumeType: '',
        // 合同模板
        templateId: '',
      },
      pageFormRules: {

      },
      contractFieldRules: {

      },
      // 签约模式 线上签约-ONLINE 线下签约-OFFLINE
      signingMode: 'ONLINE',
      imageList: [],
      contractTemplateRegion: [],
      currContractTemplate: {},
      notShowArr: [
        'znfarmername2',
        'znidno3',
        'znfarmeraddress2',
        'znidno2',
      ],
      disableField: [
        'znfarmername',
        'znidno',
        'znfarmerphone',
        'znfarmeraddress',
      ],
      contractField: {},
      //
      signUrl: '',
      signUrlShow: false,
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getInfo()
  },
  methods: {
    gridConnectionStatusMapper,
    viewImages,
    statusMapper,
    /**
     *
     */
    closePage(){
      this.$router.replace(`/admin/contract`)
    },
    /**
     *
     */
    submit(){
      // 线下签约
      const offlineSigningHandler = () => {
        this.$refs.formRef.validate((valid) => {
          if (!valid) {
            return false
          }

          const params = {
            files: this.pageForm.files.join(','),
            projectId: this.$route.query.projectId
          }

          const loading = this.$loading({ fullscreen: true })
          projectOffline(params).then(() => {
            this.$message.success('提交成功')
            setTimeout(() => {
              this.closePage()
            }, 500)
          }).finally(() => {
            loading.close()
          })
        })
      }
      // 线上签约
      const validatorContractHandler = () => {
        this.$refs.formRef.validate((valid) => {
          if (!valid) {
            return false
          }
          //
          this.$refs.contractFieldRef.validate((val) => {
            if (!val) {
              return false
            }

            // 参数拼接
            const params = {
              templateId: this.currContractTemplate.templateId,
              formFields: [],
            }
            for (let key in this.contractField) {
              params.formFields.push({
                componentName: key,
                componentValue: this.contractField[key]
              })
            }
            this.notShowArr.forEach(iter => {
              params.formFields.push({
                componentName: iter,
                componentValue: ''
              })
            })

            // 创建合同
            const loading = this.$loading({ fullscreen: true })
            saveEsign(this.$route.query.projectId, this.pageForm.consumeType, params).then(res => {
              this.$message.success('提交成功')
              setTimeout(() => {
                this.signUrl = PIC_URL + '/code?code=' + res
                this.signUrlShow = true
              }, 500)
            }).finally(() => {
              loading.close()
            })
          })
        })
      }

      switch (this.signingMode) {
        case 'ONLINE':
          validatorContractHandler()
          break
        case 'OFFLINE':
          offlineSigningHandler()
          break
      }
    },
    /**
     * 选择合同模板
     * @param e
     */
    templateChange(e){
      const item = this.contractTemplateRegion.find(iter => iter.templateId === e)
      if (item) {
        // 生成表单字段
        this.currContractTemplate = item
        // 过滤不显示字段
        const components = this.currContractTemplate.components
        this.currContractTemplate.components = components.filter(iter => !this.notShowArr.includes(iter.componentName))
        // 设置禁止修改项
        this.currContractTemplate.components.map(iter => {
          iter.isDisable = this.disableField.includes(iter.componentName)
        })
        // 填充部分表单
        const { name, idNo, phone, province, city, area, address} = this.metadata
        if (Array.isArray(this.currContractTemplate.components)) {
          this.currContractTemplate.components.forEach(iter => {
            this.$set(this.contractField, iter.componentName, '')
            switch (iter.componentName) {
              case 'znfarmername':
                this.contractField[iter.componentName] = name
                break
              case 'znidno':
                this.contractField[iter.componentName] = idNo
                break
              case 'znfarmerphone':
                this.contractField[iter.componentName] = phone
                break
              case 'znfarmeraddress':
                this.contractField[iter.componentName] = `${province}${city}${area}${address}`
                break
            }
          })
        }
      }
    },
    /**
     * 图片上传
     * @param e
     */
    handlerUploadImage(e){
      const loading = this.$loading({ fullscreen: true })
      fileUpload(e.file).then(res => {
        const name = ((res || '').split('/') || []).reverse()[0]
        this.imageList.push({
          name: name,
          url: helper.getSourceUri(res),
          value: res
        })
        this.pageForm.files.push(res)
      }).finally(() => {
        loading.close()
      })

      this.$refs.filesImgUpload.uploadFiles = this.$refs.filesImgUpload.uploadFiles.filter(iter => iter.status === 'success')
    },
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
    /**
     * 获取数据
     */
    getInfo(){
      // 合同模板
      templateAll().then(res => {
        const temp = []
        for (let item of res) {
          if (String(item.templateType) === '1') {
            temp.push(item)
          }
        }
        this.contractTemplateRegion = temp
      })
      // 用户信息
      if (this.id) {
        this.getData(this.id).then(res => {
          this.metadata = res
          //
          this.farmer = this.metadata?.farmer
          this.workOrder = this.metadata?.workOrder
          this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
          this.user = this.metadata?.workOrder?.user
          this.designData = this.metadata?.designData

          this.designDataFiles = this.showFileList(this.metadata)

        })
      } else {
        this.$message.error('页面异常, 请返回重新进入')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>