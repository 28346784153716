<template>
  <div class="page-header">
    <slot name="header-breadcrumb">
      <el-breadcrumb separator="/" v-if="breadcrumb_list.length > 1">
        <el-breadcrumb-item :key="index" v-for="(breadcrumb, index) in breadcrumb_list">
          {{ breadcrumb }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </slot>
<!--    <slot name="header-title">
      <div class="header-title">{{ title }}</div>
    </slot>-->
    <slot name="header-subtitle">
      <p>{{ subtitle }}</p>
    </slot>
    <slot name="header-content"></slot>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
      default() {
        return this.$route.meta.title
      }
    },
    subtitle: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      breadcrumb_list: [],
    }
  },
  created() {
    let that = this
    // let breadcrumb_list = [], parent = null
    // for (let matched of that.$route.matched) {
    //   if (matched.meta && matched.meta.title && matched.meta.title != parent) {
    //     breadcrumb_list.push(matched.meta.title)
    //     parent = matched.meta.title
    //   }
    // }
    this.breadcrumb_list = [that.$route.meta.parent || '首页', that.$route.meta.title]
  },
  mounted() {

  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.page-header {
  padding: 12px 20px;
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  line-height: 1.6;

  .header-title {
    margin: 12px 0 4px 0;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }

  .header-subtitle {
    margin-top: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px;
    min-height: 22px;
  }
}
</style>
