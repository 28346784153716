import request from "@/utils/request";

/**
 * 文件上传
 * @param file
 */
export function fileUpload(file) {
  let formData = new FormData()
  formData.append('file', file)
  return request.post(`/file/upload`, formData)
}

/**
 * 踏勘建站审核
 * @param wordOrderId
 * @param data
 */
export function wordOrderExamine(wordOrderId, data) {
  return request.put(`/word-order/examine/${wordOrderId}`, data)
}

export function getDesign(params) {
  return request.get('/design', {params: params})
}

/**
 * 设计审核
 * @param data
 */
export function designExamine(data) {
  return request.post('/design/examine', data)
}

/**
 * 查询设计信息
 * @param id
 * @returns {*}
 */
export function getDesignById(id) {
  return request.get(`/design/id/${id}`)
}

/**
 * 提交设计
 * @param data
 */
export function designCheck(data) {
  return request.post('/design/check', data)
}

/**
 * @param params
 */
export function getMaterial(params) {
  return request.get('/material', {params: params})
}

/**
 * 收货
 * @param data
 */
export function materialGet(data) {
  return request.post(`material/get/${data}`)
}

/**
 * 物流详情
 * @param id
 * @returns {*}
 */
export function getMaterialInfo(id) {
  return request.get(`/material/id/${id}`)
}

/**
 * 提交设计
 * @param data
 */
export function materialCheck(data) {
  return request.post('/material/check', data)
}

/**
 * @param params
 */
export function getConnection(params) {
  return request.get('/connection', {params: params})
}

/**
 * 并网审核
 * @param data
 */
export function connectionExamine(data) {
  return request.post('/connection/examine', data)
}

/**
 * 并网详情
 * @param id
 * @returns {*}
 */
export function getConnectionById(id) {
  return request.get(`/connection/id/${id}`)
}

/**
 * 并网申请
 * @param data
 */
export function connectionApply(data) {
  return request.post('/connection/apply', data)
}

/**
 * @param params
 */
export function getPay(params) {
  return request.get('/pay', {params: params})
}

/**
 * 结算详情
 * @param id
 * @returns {*}
 */
export function getPayById(id) {
  return request.get(`/pay/id/${id}`)
}

/**
 * 结算申请
 * @param data
 * @returns {*}
 */
export function payApply(data) {
  return request.post(`/pay/apply`, data)
}

// 合同列表
export function contractPage(params) {
  return request.get('/signature', {params})
}

/**
 * 撤销合同
 * @param signatureId
 * @returns {*}
 */
export function signatureRevoke(signatureId) {
  return request.post(`/signature/revoke/${signatureId}`)
}

/**
 * 合同详情
 * @returns {*}
 */
export function signatureId(id) {
  return request.get(`/signature/id/${id}`)
}

// 合同模板
export function templateAll(params) {
  return request.get('/signature/templates', params)
}

/**
 * 线下签约
 * @param data
 * @returns {*}
 */
export function projectOffline(data) {
  return request.post(`/project/offline`, data)
}

// 合同创建
export function saveEsign(projectId, type, data) {
  return request.post(`/signature/flow/${projectId}/${type}`, data)
}

export function getProject(params) {
  return request.get('/project', {params})
}

/**
 * 查询用户
 * @param id
 * @returns {*}
 */
export function getProjectUserById(id) {
  return request.get(`/farmer/id/${id}`)
}

/**
 * 重新发起合同
 * @param signatureId
 * @returns {*}
 */
export function signatureFlowAgain(signatureId) {
  return request.post(`/signature/flow/again/${signatureId}`)
}

/**
 * 撤销签约
 * @param signatureId
 */
export function initiateRescission(signatureId) {
  return request.post(`/signature/initiate-rescission/${signatureId}`)
}

//
export function getWorkIng(params) {
  return request.get('/work-ing', {params})
}

/**
 * 工单管理-施工工单
 * @param params
 */
export function getWorkIngPage(params) {
  return request.get('/work-ing/work/pages', {params})
}

/**
 * 验收详情
 * @param id
 * @returns {*}
 */
export function getWorkIngById(id) {
  return request.get(`/work-ing/id/${id}`)
}

/**
 * 验收审核
 * @param data
 * @returns {*}
 */
export function workIngExamine(data) {
  return request.post(`/work-ing/examine`, data)
}

/**
 * 获取接收施工人员 施工
 * @param params
 * @returns {*}
 */
export function getWorkingSalesman(params={}) {
  return request.get(`/user/working/salesman`, {params})
}

/**
 * 踏勘
 * @param params
 */
export function userSalesman(params={}) {
  return request.get(`/user/salesman`, {params})
}

/**
 * 验收派单
 * @returns {*}
 * @param data
 */
export function workIngPd(data) {
  return request.post(`/work-ing/pd`, data)
}

/**
 * 踏勘派单
 * @param data
 */
export function wordOrderCheck(data) {
  return request.post(`/word-order/check`, data)
}

/**
 * 转单
 */
export function wordOrderTransfer(wordOrderId, userId, data={}) {
  return request.post(`/word-order/transfer/${wordOrderId}/${userId}`, data)
}

/**
 * 解封
 * @param wordOrderId
 */
export function wordOrderOpen(wordOrderId) {
  return request.put(`/word-order/open/${wordOrderId}`)
}

/**
 * 枫丹
 * @param wordOrderId
 */
export function wordOrderClose(wordOrderId) {
  return request.put(`/word-order/close/${wordOrderId}`)
}

/**
 * 企业授权
 */
export function signatureOrgEmpower(developerId) {
  return request.get(`/signature/org-empower/${developerId}`)
}

/**
 * 印章列表
 * @param developersId
 * @param params
 */
export function getOrgOwnSealsList(developersId, params) {
  return request.get(`/signature/org-own-seals-list/${developersId}`, { params })
}

export function orgSealAuth(id, params) {
  return request.get(`/signature/org-seals/${id}`, { params })
}

//e签宝个人授权
export function psnEmpowerAuth(userId) {
  return request.get(`/signature/psn-empower/${userId}`)
}

/**
 * 下载合同
 * @param url
 */
export function downloadFile(url) {
  return request.get(`/download`, {
    responseType: 'arraybuffer',
    params: {
      filePath: url,
    }
  })
}

/**
 * 获取详情
 * @param prefix
 * @param farmerId
 */
export function getInfoFarmer(prefix, farmerId) {
  return request.get(`/${prefix}/get-by-farmer-id/${farmerId}`)
}

/**
 * 开发商
 */
export function getDistributor() {
  return request.get(`/developers/new-all-and-distributor`)
}

/**
 * 合同详情
 * @param id
 */
export function getSignatureById(id) {
  return request.get(`/signature/id/` + id)
}